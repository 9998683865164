import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Register from "./register";
import FurtherInformation from "./furtherInformation";
import Audit from "./audit";
import Monitor from "./monitor";
export default () => {
  return (
    <Router>
      <Switch>
        <Route path="/further-information/:updateCustomerUuid">
          <FurtherInformation />
        </Route>
        <Route path="/audit">
          <Audit />
        </Route>
        <Route path="/monitor">
          <Monitor />
        </Route>
        <Route path="/">
          <Register />
        </Route>
      </Switch>
    </Router>
  );
};
