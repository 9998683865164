const linkConfig = {
  web: "https://www.antofx.com",
  h5: "https://h5.antofx.com",
  online: "https://antofx.kf5.com/kchat/1040026",
  login: "https://fo.aatuser.com/Frontoffice",
  disclaimer: "https://www.antofx.com/singlepage?type=disclaimer",
  risk: "https://www.antofx.com/singlepage?type=risk_disclosure",
  agreement: "https://www.antofx.com/singlepage?type=client_agreement",
  policy: "https://www.antofx.com/singlepage?type=privacy_policy",
};

const sourceUrl='_s_u_l_';


export { linkConfig ,sourceUrl};
