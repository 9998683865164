import React, { useState, useEffect, useCallback } from "react";
import { parse } from "qs";
import Navigation from "../Navigation";
import Footer from "../Footer";
import { linkConfig } from "../../config";
import styles from "./styles.module.scss";
export default ({
  children,
  className = "",
  title = "确定放弃领取50元红包吗？",
  cancel = "放弃",
  confirm = "领取",
  redirect = "",
}) => {
  const [state, setState] = useState({
    visible: false,
  });
  const submit = useCallback(() => {
    setState((t) => ({ ...t, visible: !t.visible }));
  }, []);

  const goHomeApp = useCallback(() => {
    const sessionStorage = window.sessionStorage;
    const phone = sessionStorage.getItem("__r__");
    const pwd = sessionStorage.getItem("__p__");
    const signature = phone + "#" + pwd + "#1#0";
    if (phone && pwd) {
      if (window.isNative && window.isNative.willLoginWith) {
        window.isNative.willLoginWith &&
          window.isNative.willLoginWith(signature);
        return;
      }
      if (window.webkit && window.webkit.messageHandlers.willLoginWith) {
        window.webkit.messageHandlers.willLoginWith.postMessage({
          body: signature,
        });
        return;
      }
      if (window.willLoginWith) {
        window.willLoginWith(signature);
        return;
      }
    }

    if (window.isNative) {
      window.isNative.ResultByJs && window.isNative.ResultByJs(5);
      return;
    }
    if (window.webkit && window.webkit.messageHandlers.ResultByJs) {
      window.webkit.messageHandlers.ResultByJs.postMessage({ body: 5 });
      return;
    }
    if (window.iOSNative) {
      window.iOSNative(5);
      return;
    }
    window.location.href = redirect || linkConfig.web;
  }, [redirect]);
  return (
    <main>
      <Navigation submit={submit} />
      <div className={`${styles.main} ${className}`}>{children}</div>

      {state.visible && (
        <div className={styles.modal}>
          <div className="modal-content">
            <h3>{title}</h3>
            <div className="flex-container flex-center">
              <span onClick={goHomeApp} className="flex-block">
                {cancel}
              </span>
              <span onClick={submit} className="flex-block">
                {confirm}
              </span>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </main>
  );
};
